import React from 'react'
import designcave from '../../Assets/designcave logo.jpeg';

//Style
import "./Header.css"
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <div>
      <header className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <div className="navbar-header">
            <div className="navbar-brand">
              <img src={designcave} alt="Img" />
              {/* DesignCave */}
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="contact navbar-nav mx-auto">
              <li className="nav-item">
                <Link className="nav-link p-2" to="/home">
                  HomePage
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link p-2" to="/event">
                  Events
                </Link>
              </li>
              <li className="nav-item">
                <a className="nav-link p-2" href="#">
                  Curriculum
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link p-2" href="#">
                  Testimonials
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link p-2" href="faq">
                  FAQ
                </a>
              </li>
            </ul>
            <a className="nav-link p-2" href="https://forms.gle/xZxBZPBFMQuPhPs4A" id="join">
              <button id="join" >Join The Cohort</button>
            </a>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Header