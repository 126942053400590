import React, { useState } from 'react';
import Header from "../../Components/Header/Header";
import Footer from '../../Components/Footer/Footer';

function FAQ() {
    const questions = [
        { question: "What\u2019s the organization about?", answer: "DesignCave is a NGO dedicated to enlightening individuals about the field of product design and UI/UX, helping them make informed career decisions through comprehensive introductory training." },

        { question: "What is DesignCave?", answer: "DesignCave is a growing community dedicated to enlightening and supporting aspiring designers on their journey into UI/UX and product design. A NGO focused on providing introductory training in product design to help individuals make informed career decisions." },

        { question: "Is there a fee to join the program?", answer: "No, our programs are completely free. We aim to make the education accessible to everyone." },

        { question: "How can I register for the program?", answer: "Registration details will be provided on our social media platforms and website. Follow us for updates and announcements." },

        { question: "How long is the program?", answer: "The program lasts for 4 weeks and twice a week class (tuesday and thursday), with online visual training sessions, live lectures, interactive workshops, and collaborative projects." },

        { question: "Will participants be taught how to design proper?", answer: "No, participants will only be taught the fundamentals of product design and how to use industry-standard tools like Figma, providing them with a introductory foundation to understand how design works." },

        { question: "What do I do to join the enlightenment program?", answer: "You have to look out on all our social media pages and register when registration link is shared for the next cohort" },
    ];

    const [openIndex, setOpenIndex] = useState(null);

    const handleToggle = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <div className="container my-5">
            <Header />
            <div className="curri p-3">
                <h2 className="text-center mb-4 mt-3">Frequently Asked Questions</h2>
                <div className="accordion" id="faqAccordion">
                    {questions.map((item, index) => (
                        <div className="card" key={index}>
                            <div className="card-header mt-2" onClick={() => handleToggle(index)}>
                                <h5 className="mb-0 d-flex justify-content-between align-items-center">
                                    {item.question}
                                    <span className="plus-icon">{openIndex === index ? '-' : '+'}</span>
                                </h5>
                            </div>
                            <div id={`collapse${index}`} className={`collapse ${openIndex === index ? 'show' : ''}`} data-parent="#faqAccordion">
                                {/* <div className="card-body"> */}
                                {item.answer}
                                {/* </div> */}
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <Footer />
        </div>
    );
}

export default FAQ;
