import React, { useEffect, useState } from "react";
import FAQ from "../../Components/FAQ/FAQ";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import vector0 from "../../Assets/Vector - 0.svg";
import frame from "../../Assets/frame.svg";
import career from "../../Assets/career.svg";
import community from "../../Assets/community.svg";
import practical from "../../Assets/practical.svg";
import prcticalcur from "../../Assets/practicalcur.svg";
import intro from "../../Assets/intro.svg";
import fundamental from "../../Assets/fundamental.svg";
import usercentered from "../../Assets/usercentered.svg";
import unionImage from "../../Assets/unionImage.svg";

//style
import "./HomePage.css";

const HomePage = () => {
  console.log("Home Page");
  const [currentSlide, setCurrentSlide] = useState(0);

  const slides = [
    {
      title: "🎉 Kickstart 2025 with Cohort 3!",
      content: (
        <>
          <p>Be part of our first enlightenment session of the year! Cohort 3 starts on <strong>February 18th, 2025</strong>, and runs for 4 weeks.</p>
          <p>Join our Slack community to stay updated, connect with fellow learners, and access all the resources you need to dive into the fundamentals of UI/UX design and Figma.</p>
          <a href="https://forms.gle/xZxBZPBFMQuPhPs4A">
            <button id="join-slack">Register Now</button>
          </a>
        </>
      ),
    },
    {
      title: "🌟 Refer-a-Friend Challenge is Here! 🌟",
      content: (
        <>
          <p>Invite your friends to join our Slack community</p>
          {/* <p>
              <strong>Challenge Period:</strong> January 6 – February 3, 2025.
            </p> */}
          <p>
            <strong>How It Works:</strong>
            <br />
            1. Share the Slack invite link.
            <br />
            2. Friends join Slack and mention your Slack ID as the referral.
            <br />
            {/* 3. The top referrer wins big! */}
          </p>
          <a href="https://forms.gle/megxdj3Y93tzuM2E9">
            <button id="refer-now">Join & Share the link</button>
          </a>
        </>
      ),
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % slides.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [slides.length]);

  return (
    <div className="container-fluid">
      <div className="row">

        <Header />
        <div className="home ">
          <div className="hero align-items-center justify-content-center">
            {/* <div className="hero_img">
              <img src={unionImage} alt="Img" />
              </div> */}
            <div
              className="hero align-items-center justify-content-center mt-5"
              style={{ backgroundImage: `url(${unionImage})` }}
            >
              <div className="hero_text p-3">
                <div className="campaign-slider">
                  <div className="campaign-slide">
                    <h1>{slides[currentSlide].title}</h1>
                    {slides[currentSlide].content}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="why align-items-center justify-content-center mt-5">
            <h1>Why DesignCave</h1>
            <p className="mt-3">
              At DesignCave, we believe that great design should be accessible
              to everyone. Our program offers a comprehensive introduction to
              the field of product design, helping you understand if this
              career path is right for you before you commit your time and
              resources to boot camps or advanced training. </p> <p>While our program
                is currently free, our mission is to provide valuable insights
                and support to help you make informed decisions about your
                future in design. If you’re passionate about design and ready to
                put in the work, join our community and start your journey
                toward becoming a skilled product designer.
            </p>
          </div>

          <div className="program mt-5 mb-5">
            <h1>Program Overview</h1>
            <p>Our program is designed as an introductory course to the world of Product Design. We focus on providing basic knowledge and skills, as well as career guidance, to help you make informed decisions about your design journey before investing in advanced training. The program is structured to give you a taste of what it's like to work as a Product Designer, and to help you assess your passion and potential in this field.</p>
          </div>
          <div className="why_corner mt-5 d-flex flex-wrap justify-content-between">
            <div className="why_box p-3 align-items-center">
              <img src={vector0} alt="Icon" />
              <h1>No cost</h1>
              <p>Our program is entirely free of charge, removing financial barriers so you can focus on learning and growth. We believe that quality education should be accessible to everyone, enabling you to start your design journey without any upfront expenses.</p>
            </div>
            <div className="why_box p-3 align-items-center">
              <img src={frame} alt="Icon" />
              <h1>Portfolio focused</h1>
              <p>Build a professional, high-quality portfolio that highlights your creativity, technical skills, and problem-solving abilities.</p>
            </div>
            <div className="why_box p-3 align-items-center">
              <img src={career} alt="Icon" />
              <h1>Career support</h1>
              <p>Gain personalized career guidance, including assistance with crafting a compelling resume, optimizing your LinkedIn profile, and preparing a portfolio that sets you apart. We’re here to help you navigate the job market with confidence.</p>
            </div>
          </div>
          <div className='
            d-flex flex-wrap justify-content-center'>
            <div className="why_box p-3 align-items-center">
              <img src={community} alt="Icon" />
              <h1>Community-driven learning</h1>
              <p>
                Join a vibrant community of designers who share insights, provide support, and inspire each other. Our collaborative environment encourages networking and collective growth, making learning more engaging and inclusive.
              </p>
            </div>
            <div className="why_box p-3 align-items-center">
              <img src={practical} alt="Icon" />
              <h1>Practical skills</h1>
              <p>
                Master the tools and techniques used in the industry today, from wireframing and prototyping to user research and design systems. Our hands-on approach ensures you're job-ready and capable of tackling real-world challenges.
              </p>
            </div>
          </div>
          <div className="ready mt-5 mb-4 text-center d-block">
            <h1>🚀 Cohort 3 is Almost Here!</h1>
            <p>Our first enlightenment session of the year starts soon! Be among the first to access the fundamentals of UI/UX in this 4-week free training.</p>
            <p>Stay updated—click the link to join our Slack community and secure your spot! 🔗</p>
            <a href="https://forms.gle/xZxBZPBFMQuPhPs4A">
              <button className="ready text-center" id="join">Join Our Slack Community</button>
            </a>
          </div>


          <div className="curriculum mt-5 mb-3">
            <h1>Curriculum</h1>
            <div className="curriculum_box d-flex flex-wrap mt-3 justify-content-between">
              <div className="box">
                <img src={intro} alt="Img" />
                <p>Introduction to UI/UX</p>
              </div>
              <div className="box">
                <img src={fundamental} alt="Img" />
                <p>Fundamentals of Figma</p>
              </div>
              <div className="box">
                <img src={usercentered} alt="Img" />
                <p>User- Centered Design</p>
              </div>
              <div className="box">
                <img src={prcticalcur} alt="Img" />
                <p>Practical Application</p>
              </div>
            </div>
          </div>

          <FAQ />
        </div>
        <Footer />
      </div>
      <script async src="https://eocampaign1.com/form/b8ab6c36-bc82-11ef-89f5-85263dcbd4b2.js" data-form="b8ab6c36-bc82-11ef-89f5-85263dcbd4b2"></script>
    </div>
  );
}

export default HomePage;
